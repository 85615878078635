import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
import '@morioh/v-lightbox/dist/lightbox.css';
import Lightbox from '@morioh/v-lightbox'
import {auth} from './firebase'

import AOS from 'aos'
import 'aos/dist/aos.css'
import Croppa from 'vue-croppa';
Vue.use(VCalendar);
Vue.use(Lightbox);

Vue.use(Croppa);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

require('./plugins/fontawesome');

Vue.config.productionTip = false



auth.onAuthStateChanged(user => {
  if(user){
    const detectoUsuario = {
    email: user.email,
    uid: user.uid
    }
    store.dispatch('detectarUsuario', detectoUsuario)
  }else{
    store.dispatch('detectarUsuario', user)
  }
  new Vue({
    created () {
      AOS.init()
    },
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})


