import Vue from "vue";
import Vuex from "vuex";

import {auth, db, secondaryApp} from "../firebase";
import router from "../router";

import moment from "moment";
moment.locale("es");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuario: null,
    error: null,
    carga: false,
  },
  mutations: {
    setUsuario(state, payload) {
      state.usuario = payload;
    },
    getUsuario(state) {
      return state.usuario;
    },
    setError(state, payload) {
      state.error = payload;
    },
    cargarFirebase(state, payload) {
      if (payload == false) {
        setTimeout(() => (state.carga = payload), 2000);
      } else {
        state.carga = payload;
      }
    },
  },
  actions: {
    crearUsuario({commit}, usuario) {
      auth
        .createUserWithEmailAndPassword(usuario.email, usuario.password)
        .then((res) => {
          commit("cargarFirebase", true);
          db.collection("users")
            .doc(res.user.uid)
            .set({
              mail: res.user.email,
              role: "user",
              name: usuario.name,
              surname: usuario.surname,
              dni: usuario.dni,
              phone: usuario.phone,
              number: "",
              state: "inactivo",
              foto: "",
              date: usuario.date,
              address: usuario.address,
              employer: usuario.employer,
              civilState: "",
              childs: 0,
              childsArray: [
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
              ],
            })
            .then((doc) => {
              const user = {
                email: res.user.email,
                uid: res.user.uid,
                name: usuario.name,
                role: "user",
                surname: usuario.surname,
                dni: usuario.dni,
                phone: usuario.phone,
                state: "inactivo",
                number: "",
                foto: "",
                date: usuario.date,
                address: usuario.address,
                employer: usuario.employer,
                civilState: usuario.civilState,
              };
              db.collection("users")
                .doc(res.user.uid)
                .collection("notificaciones")
                .doc("welcome")
                .set({
                  message: "Bienvenido/a a Mutual Mercantil, no olvides actualizar tus datos y foto de perfil ",
                  state: true,
                });
              commit("cargarFirebase", false);
              commit("setUsuario", user);
              var formData = new FormData();
              formData.append("uid", res.user.uid);
              formData.append("email", res.user.email);
              fetch("https://mutualmercantil.ar/post-alta.php", {
                method: "POST",
                body: formData,
              });
              router.push("/mi-cuenta");
            })
            .catch((error) => {
              commit("setError", error);
              commit("cargarFirebase", false);
            });
        })
        .catch((error) => {
          commit("setError", error);
          commit("cargarFirebase", false);
        });
    },
    crearUsuarioAdherente({commit}, usuario) {
      secondaryApp
        .auth()
        .createUserWithEmailAndPassword(usuario.email, usuario.dni)
        .then((res) => {
          commit("cargarFirebase", true);
          db.collection("users")
            .doc(res.user.uid)
            .set({
              mail: res.user.email,
              role: "user",
              name: usuario.name,
              surname: usuario.surname,
              dni: usuario.dni,
              phone: usuario.phone,
              number: usuario.number,
              state: "inactivo",
              foto: "",
              date: usuario.date,
              address: usuario.address,
              employer: "Socio Adherente",
              civilState: "",
              childs: 0,
              childsArray: [
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
                {name: "", date: ""},
              ],
              pagos: [{fecha: moment().format("L"), id: "00", registrante: `${usuario.registrantSurname}, ${usuario.registrantName}`}],
            })
            .then((doc) => {
              commit("cargarFirebase", false);

              // var formData = new FormData();
              // formData.append("dni", usuario.dni);
              // formData.append("email", res.user.email);
              // fetch("https://mutualmercantil.ar/post-adherente.php", {
              //   method: "POST",
              //   body: formData,
              // });
              secondaryApp.auth().signOut();
              router.push("/adherentes");
            })
            .catch((error) => {
              commit("setError", error);
              commit("cargarFirebase", false);
            });
        })
        .catch((error) => {
          commit("setError", error);
          commit("cargarFirebase", false);
        });
    },
    ingresoUsuario({commit}, usuario) {
      commit("cargarFirebase", true);
      auth
        .signInWithEmailAndPassword(usuario.email, usuario.password)
        .then((res) => {
          db.collection("users")
            .doc(res.user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const user = {
                  email: res.user.email,
                  uid: res.user.uid,
                  name: doc.data().name,
                  role: doc.data().role,
                  surname: doc.data().surname,
                  state: doc.data().state,
                  foto: doc.data().foto,
                  employer: doc.data().employer,
                };
                commit("cargarFirebase", false);
                commit("setUsuario", user);
                router.push("/mi-cuenta");
              } else {
                // doc.data() will be undefined in this case
              }
            })
            .catch((error) => {
              commit("setError", error);
              commit("cargarFirebase", false);
            });
        })
        .catch((error) => {
          commit("setError", error);
          commit("cargarFirebase", false);
        });
    },
    cerrarSesion({commit}) {
      commit("cargarFirebase", true);
      auth
        .signOut()
        .then(() => {
          router.push("/ingresar");
          commit("cargarFirebase", false);
        })
        .catch((error) => {
          // An error happened.
        });
    },
    detectarUsuario({commit}, usuario) {
      commit("cargarFirebase", true);
      if (usuario !== null) {
        db.collection("users")
          .doc(usuario.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const user = {
                email: usuario.email,
                uid: usuario.uid,
                name: doc.data().name,
                surname: doc.data().surname,
                role: doc.data().role,
                state: doc.data().state,
                foto: doc.data().foto,
                employer: doc.data().employer,
              };
              commit("cargarFirebase", false);
              commit("setUsuario", user);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            commit("setError", error);
          });
      }
      commit("cargarFirebase", false);
      commit("setUsuario", usuario);
    },
  },
  getters: {
    existeUsuario(state) {
      if (state.usuario === null) {
        return false;
      } else {
        return true;
      }
    },
  },
  modules: {},
});
